import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import {SidenavModule} from './sidenav/sidenav.module';
import {DatePipe, Location} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE} from '@angular/material/core';

@NgModule({
    declarations: [
        AppComponent,
    ],
    exports: [
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        SidenavModule,
        HttpClientModule,
        FlexLayoutModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule
    ],
    providers: [
        DatePipe,
        Location,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
