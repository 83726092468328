<div class="cf-vert-center cf-bg-login">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-card class="cf-center cf-login">
      <img mat-card-image src="assets/img/logo/be-tennis-logo.png" class="cf-logo">
      <mat-card-title>
        Login
      </mat-card-title>
      <mat-error *ngIf="wrongCredentials">Email und/oder Passwort falsch</mat-error>
      <mat-form-field>
        <mat-label>E-Mail Adresse</mat-label>
        <input matInput type="email" id="inputEmail" formControlName="email" placeholder="Email Adresse">
        <mat-error *ngIf="f.email.invalid">Gültige E-Mail Adresse eingeben</mat-error>
      </mat-form-field><br/>
      <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input matInput type="password" id="inputPasswort" formControlName="password" placeholder="Passwort">
      </mat-form-field><br/>
      <button mat-raised-button type="submit" class="cf-button" color="primary" style="margin-right:5px;">Login</button>
      <div *ngIf="loading" style="margin-top: 20px;">
        <mat-spinner class="center" diameter="40" color="primary">
        </mat-spinner>
      </div>
    </mat-card>
  </form>
</div>
