import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {first} from 'rxjs/operators';
import {SeasonsService} from '../_services/seasons.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  loading = false;
  seasons: any;
  wrongCredentials = false;

  private readonly CURRENT_SEASON = 'CURRENT_SEASON';
  private readonly NEXT_SEASON = 'NEXT_SEASON';

  @Output() SuccessfulLogin: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private seasonsService: SeasonsService,
  ) {
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {

    this.authenticationService.onBadLogin(this.onBadLogin.bind(this));
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  onBadLogin() {
    this.loading = false;
    this.wrongCredentials = true;
  }

  get f() {return this.loginForm.controls; }

  storeSeasons() {
    console.log(localStorage.getItem(this.CURRENT_SEASON));
    for (const season of this.seasons) {
        if (season.state === 2) {
          localStorage.setItem(this.CURRENT_SEASON, season.id);
        }
        if (season.state === 0) {
          localStorage.setItem(this.NEXT_SEASON, season.id);
        }
      }
    console.log(localStorage.getItem(this.CURRENT_SEASON));
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login( { email: this.f.email.value, username: this.f.email.value, password: this.f.password.value})
      .pipe(first())
      .subscribe(
        success => {
          if (success) {
            this.SuccessfulLogin.emit();
            if (this.route.snapshot.queryParams.returnUrl) {
              this.router.navigate([this.route.snapshot.queryParams.returnUrl]);
            } else {
              this.router.navigate(['/']);
            }
            this.seasonsService.getSeasons().subscribe(
              seasons => this.seasons = seasons,
              () => {},
              () => {
                console.log(this.seasons);
                this.storeSeasons();
              }
            );
          }
        });
  }

}
