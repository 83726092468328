  <mat-sidenav-container *ngIf="isLoggedIn" class="sidenav-container">
    <mat-sidenav position="start" #drawer class="sidenav cf-nav-color" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false"
                 [@onSideNavChange]="sideNavState ? 'open' : 'close'">
      <div class="sidenav_container" >
        <mat-nav-list>
          <a mat-list-item routerLink="{{navItem.link}}" (click)="toggleDrawer()" routerLinkActive="cf-active" *ngFor="let navItem of navItems">
            <mat-icon>{{navItem.icon}}</mat-icon><span [@animateText]="linkText ? 'show' : 'hide'" class="nav-caption">{{navItem.name}}</span>
          </a>
          <div class="buttonHeight" *ngIf="!(isHandset$ | async)">
            <button mat-mini-fab color="white" (click)="onSidenavToggle()">
              <mat-icon *ngIf="sideNavState">arrow_left</mat-icon>
              <mat-icon *ngIf="!sideNavState">arrow_right</mat-icon>
            </button>
          </div>
          <a routerLinkActive="cf-active" class="cf-new-season" mat-list-item routerLink="/create-season" (click)="toggleDrawer()">
            <mat-icon class="icon-color">cached</mat-icon><span [@animateText]="linkText ? 'show' : 'hide'" class="nav-caption icon-color">Neue Saison beginnen</span>
          </a>
          <a routerLinkActive="cf-active" class="cf-settings" mat-list-item routerLink="/settings" (click)="toggleDrawer()">
            <mat-icon class="icon-color">settings</mat-icon><span [@animateText]="linkText ? 'show' : 'hide'" class="nav-caption icon-color">Einstellungen</span>
          </a>
          <a class="cf-logout" mat-list-item (click)="onLogout()">
            <mat-icon class="icon-color">input</mat-icon><span [@animateText]="linkText ? 'show' : 'hide'" class="nav-caption icon-color">Logout</span>
          </a>
        </mat-nav-list>
      </div>
    </mat-sidenav>
    <mat-sidenav-content [@onMainContentChange]="sideNavState ? 'open': 'close'">
      <mat-toolbar class="cf-toolbar-color app-toolbar">
        <button
          style="margin-right: auto; margin-left: -5px;"
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <h1 class="mat-h1 cf-h1">{{toolbarTitle}}</h1>
        <img *ngIf="!(isHandset$ | async)" src="../../assets/img/logo/be-tennis-logo.png" class="cf-logo">
        <img *ngIf="(isHandset$ | async)" src="../../assets/img/logo/be-tennis-logo.png" class="cf-logo">
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-login *ngIf="!isLoggedIn" (SuccessfulLogin)="getStatus()"></app-login>

