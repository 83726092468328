import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  public sideNavState$: Subject<boolean> = new Subject();

  public menuTitle$: Subject<any> = new Subject<any>();

  private title = [];

  private spinnerBol_ = false;

  public spinnerBol$: Subject<any> = new Subject<any>();

  constructor() { }

  getTitle(): Observable<any> {
    return this.menuTitle$.asObservable();
  }

  clearTitle() {
    this.title.pop();
  }

  setTitle(title: any) {
    if (this.title.length === 0) {
      this.title.push(title);
      this.menuTitle$.next({newTitle: title});
    } else {
      const _title = this.title[0] + ' > ' + title;
      this.title.push(' > ' + title);
      this.menuTitle$.next({newTitle: _title});
    }

  }

  changeSpinnerBol() {
    this.spinnerBol_ = !this.spinnerBol_;
    this.spinnerBol$.next({bol: this.spinnerBol_});
  }

  getSpinnerBol(): Observable<any> {
    return this.spinnerBol$.asObservable();
  }
}
