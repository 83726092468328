import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { Tokens } from '../_classes/tokens';
import {BASE_URL} from '../globals';
import {UrlService} from "./url.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  baseUrl = BASE_URL;

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly CURRENT_SEASON = 'CURRENT_SEASON';
  private readonly NEXT_SEASON = 'NEXT_SEASON';

  public loggedUser: string;
  private loggedUserSubject: BehaviorSubject<any>;
  public loggedUserObservable: Observable<any>;

  private myFunc: () => void;
  onBadLogin(fn: () => void) {
    this.myFunc = fn;
  }

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
    this.loggedUserSubject = new BehaviorSubject<any>(localStorage.getItem(this.JWT_TOKEN));
    this.loggedUserObservable = this.loggedUserSubject.asObservable();
  }

  login(user: { username: string, email: string, password: string}): Observable<boolean> {
    return this.http.post<any>(`${this.baseUrl}api/admin-requests/login/`, user).pipe(
      tap(tokens => this.doLoginUser(user.username, tokens),
        error => {
        this.myFunc();
        }),
      mapTo(true),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  logout() {
    return this.http.post<any>(`${this.baseUrl}rest-auth/logout/`, {
      refreshToken: this.doLogoutUser() // eigentlich refreshToken() aber server gibt noch keinen refresh token zurück
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(`https://cfsepp.uber.space/api/api-token-refresh/`, {
      token: this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.token);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
    console.log(tokens.user);
  }

  private doLogoutUser() {
    console.log('Logout user');
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.token);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}


