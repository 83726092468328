import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, mapTo, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SeasonServerI} from '../_classes/planner';
import {BASE_URL} from '../globals';
import {UrlService} from "./url.service";

@Injectable({
  providedIn: 'root'
})
export class SeasonsService {

  baseUrl = BASE_URL;

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
    this.baseUrl = this.urlService.getURL();
  }

  getCurrentSeason(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/current-season/`).pipe(
      tap(_ => console.log('fetched current season'))
    );
  }

  getLastSeason(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/last-season/`).pipe(
      tap(_ => console.log('fetched last season'))
    );
  }

  getPlanningSeason(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/planning-season/`).pipe(
      tap(_ => console.log('fetched planning season'))
    );
  }

  getSeasonStates(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/season-state/`).pipe(
      tap(_ => console.log('fetched season states'))
    );
  }

  getSeasons(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/admin-requests/seasons/`).pipe(
      tap(_ => console.log('fetched seasons'))
    );
  }

  createSeason(season) {
    return this.http.post(`${this.baseUrl}api/admin-requests/seasons/`, season).pipe(
      tap(() => console.log('season created'))
    );
  }

  updateSeason(season: SeasonServerI) {
    return this.http.patch(`${this.baseUrl}api/admin-requests/seasons/${season.id}/`, season).pipe(
      tap(() => console.log('season created'))
    );
  }

  createSeasonIntermissions(intermissions) {
    return this.http.post(`${this.baseUrl}api/admin-requests/intermissions-season/`, intermissions).pipe(
      tap(() => console.log('intermissions created'))
    );
  }
}
