import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './_helpers';
import {CanDeactivateGuard} from './_services/can-deactivate.guard';


const routes: Routes = [
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
    { path: 'players', loadChildren: () => import('./players/players.module').then(m => m.PlayersModule), canActivate: [AuthGuard] },
    { path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule), canActivate: [AuthGuard] },
    { path: 'trainers', loadChildren: () => import('./trainers/trainers.module').then(m => m.TrainersModule) },
    { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
    { path: 'last-season', loadChildren: () => import('./last-season/last-season.module').then(m => m.LastSeasonModule)},
    { path: 'current-season', loadChildren: () => import('./current-season/current-season.module').then(m => m.CurrentSeasonModule)},
    { path: 'next-season', loadChildren: () => import('./next-season/next-season.module').then(m => m.NextSeasonModule) },
    { path: 'create-season', loadChildren: () => import('./create-season/create-season.module').then(m => m.CreateSeasonModule) },
    { path: 'mailing', loadChildren: () => import('./mailing/mailing.module').then(m => m.MailingModule) },
    { path: 'accounts', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)},
    { path: 'last-season', loadChildren: () => import('./last-season/last-season.module').then(m => m.LastSeasonModule) },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
