import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() {}

  getURL() {
    console.log(window.location.host);
    if (window.location.host === 'admin.betennis.net') {
      console.log('Production page');
      return 'https://backend.betennis.net/';
    } else if (window.location.host === 'admin.cloudfood.django.group') {
      console.log('Test page');
      return 'http://backend.cloudfood.django.group/';
    } else {
      // return 'http://backend.cloudfood.django.group/';
      // return 'https://backend.betennis.net/';

      return 'http://127.0.0.1:8000/';
    }
  }
}
