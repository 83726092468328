import {Component, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, OnInit, ChangeDetectorRef} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {AuthenticationService} from '../_services/authentication.service';
import {Router} from '@angular/router';
import {onSideNavChange, animateText, onMainContentChange} from '../animations/animations';
import {SidenavService} from '../_services/sidenav.service';

export interface NavItemI {
  name: string;
  icon: string;
  link: string;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [onSideNavChange, animateText, onMainContentChange]
})
export class SidenavComponent implements OnDestroy, OnInit {

  @Output() public logout = new EventEmitter();

  @ViewChild('drawer') drawer: any;

  navItems: NavItemI[] = [
    {name: 'Dashboard', icon: 'home', link: '/dashboard'},
    {name: 'Accounts', icon: 'account_circle', link: '/accounts'},
    {name: 'Spieler', icon: 'person', link: '/players'},
    {name: 'Letzte Saison', icon: 'history', link: '/last-season'},
    {name: 'Aktuelle Saison', icon: 'today', link: '/current-season'},
    {name: 'Nächste Saison', icon: 'update', link: '/next-season'},
    {name: 'Trainer', icon: 'sports_tennis', link: '/trainers'},
    {name: 'Mail', icon: 'mail', link: '/mailing'}
  ];

  isLoggedIn = false;

  linkText = false;
  sideNavState = false;

  log = false;
  handheldBol = false;

  subscription: Subscription;
  toolbarTitle = '';
  spinnerSubscription: Subscription;
  spinnerBol = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  disableSidenav() {
    this.log = true;
  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;

    if (this.sideNavState) {
      setTimeout(() => {
        this.linkText = this.sideNavState;
      }, 300);
    } else {
      this.linkText = this.sideNavState;
    }
  }

  toggleDrawer() {
    if (this.handheldBol) {
      this.drawer.toggle();
    }
  }
  public onLogout = () => {
    this.authenticationService.logout();
    this.drawer.toggle();
    this.disableSidenav();
    this.router.navigate(['/login']);
    this.getStatus();
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private router: Router,
    private sidenavService: SidenavService,
    private ref: ChangeDetectorRef
    ) {
    this.toolbarTitle = '';
    breakpointObserver.observe(Breakpoints.Handset).subscribe(
      result => {
        this.handheldBol = result.matches;
      });
    this.getStatus();
  }

  public getStatus() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
  }

  ngOnInit() {
    this.subscription = this.sidenavService.getTitle().subscribe(
      title => {
        this.toolbarTitle = title.newTitle;
        this.ref.detectChanges();
        },
    );
    this.spinnerSubscription = this.sidenavService.getSpinnerBol().subscribe(
      spinnerBol => {
        this.spinnerBol = spinnerBol.bol;
        this.ref.detectChanges();
      }
    );
  }

  changeSpinnerBol() {
    this.sidenavService.changeSpinnerBol();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.spinnerSubscription.unsubscribe();
  }

}
