import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidenavRoutingModule } from './sidenav-routing.module';
import { SidenavComponent } from './sidenav.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { LoginModule } from '../login/login.module';
import {FlexModule} from '@angular/flex-layout';


@NgModule({
  declarations: [SidenavComponent],
  exports: [
    SidenavComponent
  ],
    imports: [
        CommonModule,
        SidenavRoutingModule,
        AngularMaterialModule,
        LoginModule,
        FlexModule,
    ]
})
export class SidenavModule { }
